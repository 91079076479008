@use "@angular/material" as mat;
@use "./themes";
//@import "@angular/material/theming";

@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.elevation-classes();
@include mat.app-background();

// TODO: Maybe convert to user specific compoent themes?
// https://material.angular.io/guide/theming#applying-a-theme-to-components
// TODO: Maybe load dark theme by default? This will change which theme needs the density and typography
@include mat.all-component-themes(themes.$api-app-light-theme);
html.dark {
  @include mat.all-component-colors(themes.$api-app-dark-theme);
}

// Angular Calendar
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  @apply m-0 bg-neutral-700;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h2,
h3,
h4 {
  font-weight: 500;
}

ul {
  list-style: disc;
  margin-block-start: 1em;
  padding-inline-start: 1.5em;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
}

strong {
  font-weight: 500;
}

.fxFlexBreak {
  flex-basis: 100%;
  padding: 0 !important;
}

mat-form-field {
  width: 100%;
}

ngx-monaco-editor,
ngx-monaco-diff-editor {
  height: 100% !important;
  width: 100% !important;

  .peekview-widget .preview.inline {
    display: inline-block !important;
  }
}

.mat-sort-header-arrow {
  color: inherit;
}

.mat-card-header-text {
  flex: 1 1 100%;
  width: 0;
}

.mat-mdc-menu-panel {
  max-width: 330px;
}
.mat-dialog-content .main-wrapper {
  height: 95.7vh !important;
}
.mat-dialog-content h4 {
  margin-top: 0;
}

app-loading-spinner {
  width: 100%;
}

app-dialog-fullscreen {
  .mat-sidenav-container {
    background-color: #fff;
    height: 100% !important;
  }

  .mat-dialog-content {
    height: 100%;
    padding-bottom: 0 !important;
  }
}

html.dark {
  input.client-filter-input {
    color: #fff;
  }

  .mat-sort-header-arrow {
    color: #fff;
  }
}

/* Scrollbars */
::-webkit-scrollbar {
  @apply h-2 w-2;
}
.thin-scrollbar::-webkit-scrollbar {
  @apply h-[3px] w-[3px];
}
::-webkit-scrollbar-track {
  @apply rounded-lg bg-neutral-500 bg-opacity-30;
}
::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-neutral-400;
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-opacity-80;
}
::-webkit-scrollbar-corner {
  @apply bg-transparent;
}

.tiny-fab {
  @apply ml-2 h-8 w-8 text-base #{!important};
}
.snackbar-error .mdc-snackbar__surface {
  @apply bg-warn #{!important};
}
.snackbar-error .mdc-snackbar__label {
  color: white !important;
}

// Form field buttons
.mat-mdc-form-field-icon-suffix {
  a,
  button {
    @apply flex h-8 w-8 items-center justify-center p-0 #{!important};

    fa-icon {
      @apply leading-8;
    }

    svg {
      @apply h-4 w-4 #{!important};
    }
  }
}

.mdc-icon-button fa-layers,
.mdc-icon-button fa-icon {
  @apply block h-full w-full leading-none;
}

.mat-mdc-icon-button.mat-mini-icon-button {
  @apply p-[6px];
  --mdc-icon-button-state-layer-size: 28px;
  --mdc-icon-button-icon-size: 16px;
}

// CHIPS {
.grid-chips {
  @apply flex-grow;

  .mdc-evolution-chip-set__chips {
    @apply grid grid-cols-fill-36;
  }
  .mdc-evolution-chip__action {
    @apply w-full justify-start;
  }
  .mdc-evolution-chip__graphic {
    @apply flex-grow-0;
  }
  .mdc-evolution-chip__text-label {
    @apply overflow-hidden pr-2 #{!important};
  }
}

//TOOLTIPS
.mat-mdc-tooltip-panel {
  pointer-events: none;
}
mat-tooltip-component .mdc-tooltip {
  &.user-tooltip {
    white-space: pre-line;
  }
  &.modified-service-tooltip {
    position: relative;
    top: 30px;
  }
}

// ACCORDIONS
mat-expansion-panel {
  @apply dark:bg-component-bg-dark #{!important};
}
.mat-expansion-indicator::after {
  @apply border-neutral-700 dark:border-neutral-400;
}
.mat-expansion-panel-body {
  @apply border-t-4 border-solid border-t-primary pt-4 #{!important};
}

// TOOLBARS
mat-toolbar.placeholder {
  @apply h-auto whitespace-normal rounded border border-neutral-200 bg-white py-3 text-lg dark:border-neutral-700 dark:bg-body-bg-dark dark:text-white #{!important};
}
mat-expansion-panel,
mat-card {
  mat-toolbar.placeholder {
    @apply bg-body-bg-light #{!important};
  }
}

// SIDENAV
mat-sidenav-container {
  @apply bg-transparent #{!important};
}
mat-sidenav {
  @apply dark:bg-component-bg-dark #{!important};
}

// TOGGLES
mat-button-toggle {
  @apply dark:bg-component-bg-dark #{!important};
}
mat-button-toggle.mat-button-toggle-checked {
  @apply dark:bg-body-bg-dark #{!important};
}

// MENUS
.mdc-menu-surface {
  @apply dark:bg-component-bg-dark #{!important};
}

// DIALOGS
.mdc-dialog__surface {
  @apply dark:bg-body-bg-dark #{!important};
}

// TABLE
.mdc-data-table__header-cell {
  @apply dark:bg-component-bg-dark #{!important};
}
.mat-mdc-table {
  @apply dark:bg-component-bg-dark #{!important};
}
.mat-mdc-row:nth-child(odd) {
  @apply dark:bg-[#202b30] #{!important};
}
.mat-mdc-row.non-active {
  @apply dark:bg-[#172125] #{!important};
}
mat-paginator {
  @apply dark:bg-component-bg-dark #{!important};
}

// CARDS
mat-card {
  @apply dark:bg-component-bg-dark #{!important};
}

// FORMS
.mdc-text-field {
  @apply dark:bg-black dark:bg-opacity-10 #{!important};
}
.mat-horizontal-stepper {
  @apply bg-transparent #{!important};
}
.mat-mdc-form-field-infix {
  @apply flex;
}

// TREES
mat-tree {
  @apply bg-transparent #{!important};
}

// CHIPS
.mat-mdc-standard-chip:not(.mdc-evolution-chip--selected)
  .mdc-evolution-chip__action:before {
  @apply dark:border-neutral-500 #{!important};
}

// DRAG AND DROP
.column-list {
  @apply dark:border-neutral-500 #{!important};
}
.column-box {
  @apply dark:border-b-neutral-500 dark:bg-black dark:bg-opacity-10 #{!important};
}
.column-box.cdk-drag-preview {
  @apply dark:bg-body-bg-dark dark:bg-opacity-100 dark:text-white #{!important};
}

.code-editor:fullscreen {
  @apply bg-component-bg-light p-10 dark:bg-component-bg-dark #{!important};
}

// DATEPICKER
.mat-mdc-form-field.controltype-datepicker {
  .mat-mdc-form-field-infix {
    padding-top: 6px;
  }

  .mat-mdc-input-element {
    padding-top: 8px;
  }
}

.mat-mdc-option {
  @apply py-[5px] #{!important};
}
